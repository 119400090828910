<template>
  <v-app>
    <main>
      <div>
        <br>
        <img
          :src="bgImage"
          alt="Hi"
          class="bg-img"
        >
        <v-row
          align="center"
          justify="center"
        />
        <br>
        <v-card
          class="my-base-vcard-style"
          elevation="0"
        >
          <br>
          <br>
          <br>
          <h1 class="title-style">
            Submit Details
          </h1>
          <div>
            <v-container
              fluid
              class="py-0"
            >
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="firstName"
                        label="First Name*"
                        outlined
                        dense
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="lastName"
                        outlined
                        dense
                        class="field-style"
                        label="Last Name*"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="email"
                        outlined
                        dense
                        class="field-style"
                        label="Email"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="phoneNumber"
                        outlined
                        dense
                        class="field-style"
                        label="Phone Number*"
                        type="number"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn
                      class="btn-style"
                      color="#37474F"
                      @click="submitForm()"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn
                      class="btn-style"
                      color="#FF3700"
                      @click="cancel()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <br>
            </v-container>
          </div>
        </v-card>
        <dashboard-core-footer />
      </div>
    </main>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'UserDetailsForm',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        bgImage: require('@/assets/qr-code.jpg'),
        loading: false,
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: '',
        valid: false,
      };
    },
    computed: {
      qrCodeBannerId () {
        return this.$store.getters['qrcodebanners/getQrCodeBannerId'];
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.qrCodeBannerId === null) {
          vm.$router.push({ name: 'QR Code Redirect Failure' });
        } else {
          next();
        }
      });
    },
    methods: {
      async submitForm () {
        if (this.qrCodeBannerId === null) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Invalid banner details',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          this.$router.push({ name: 'QR Code Redirect Failure' });
          return;
        }
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('qrcodebanners/submitQrScannedUserDetails', {
          bannerId: this.qrCodeBannerId,
          userDetails: {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email !== '' ? this.email : null,
            phone_number: this.phoneNumber,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Details submitted successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('qrcodebanners/clearQrCodeBannerId');
          this.$router.push({ name: 'QR Code Redirect Success' });
        }).catch(() => {
          this.$store.dispatch('qrcodebanners/clearQrCodeBannerId');
          this.$router.push({ name: 'QR Code Redirect Failure' });
        });
      },
      cancel () {
        this.$router.push({ name: 'QR Code Redirect Failure' });
      },
    },
  };
</script>
<style scoped>
.my-style {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 15px;
}
.my-base-vcard-style {
  background-color: transparent !important;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -60px;
  border-top: 3px solid #F5F5F5;
  border-left: 3px solid #F5F5F5;
  border-right: 3px solid #F5F5F5;
}
.bg-img {
  width: 100%;
  height: 40vh;
  object-fit: contain;
  margin-top: -30px;
}
.field-style {
  margin-top: -30px;
}
.btn-style {
  margin-top: -20px;
  width: 100%;
  border-radius: 25px;
}
.title-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  margin-left: 30px;
}
</style>
